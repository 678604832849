<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
// import DialogPopUp from "../ComponentSupport/DialogPopUp.vue"
import { Helper } from '../../../../helper.js';
import Config from "../../../../config.js";
import Multiselect from "vue-multiselect";

// import vSelect from 'vue-select'

/**
 * Orders Component
 */

export default {
  mixins:[Helper],
  components: { 
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      userStart:JSON.parse(localStorage.getItem('user')),
      data_url:Config,
      errors:[],
      input_form:{
        id:"",
        name:"",
        street:"",
        street2:"",
        city:"",
        stateId:"",
        zip:"",
        jobPosition:"",
        phone:"",
        mobile:"",
        email:""
      },

      state_name:"",
      dataProvinsi:[],
      c_holding:"",
      dataSelectHolding:[],

      tab:1,
      title: "Client",
      loading:false,
      items: [
        {
          text: "Client"
        },
        {
          text: "View",
          active: true
        }
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "email", sortable: true },
        { key: "city", sortable: true, label: "City" },
        { key: "mobile", sortable: true, label: "Mobile" },
        { key: "state_name", sortable: true, label: "State Name" },
        { key: "action" }
      ]
    };
  },
  computed: {
    rows() {
      return this.ordersData.length;
    },
    v_data_provinsi_computed:{
        get(){
          return this.state_name;
        },
        set(val){
          this.state_name = val;
          this.input_form.state_id = val.id;     
        }
      },
    v_data_holding_computed:{
        get(){
          return this.c_holding;
        },
        set(val){
          this.c_holding = val;
          this.input_form.holding_id = val.id;     
        }
      }
  },
  mounted() {
    this.ApiCallDataCustomer();
    this.ApiCallDataProvinsi();
    this.ApiCallDataHolding();
    this.totalRows = this.ordersData.length;
  },
  methods: {

    cancelEdit(){
      this.state_name = {
        name: '',
        id:''
      };

      this.input_form = {
        id:"",
        name:"",
        street:"",
        street2:"",
        city:"",
        state_id:"",
        zip:"",
        job_position:"",
        phone:"",
        mobile:"",
        email:"",
        holding_id:""
      }
    },

    editRecord(data){
      this.tab = 2;

      this.state_name = {
        name: data.item.state_name,
        id:data.item.state_id
      };

      this.c_holding = {
        name:data.item.holding_name,
        id:data.item.holding_id
      };

      this.input_form = {
        id:data.item.id,
        name:data.item.name,
        street:data.item.street,
        street2:data.item.street2,
        city:data.item.city,
        state_id:data.item.state_id,
        zip:data.item.zip,
        job_position:data.item.job_position,
        phone:data.item.phone,
        mobile:data.item.mobile,
        email:data.item.email,
        holding_id:data.item.holding_id
      }
    },

    deleteRecord(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure wants to delete?", {
          title: "Please Confirm",
          size: "mm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.ApiDeleteData(data);
          }
        });
    },

    async ApiDeleteData(data){

      this.$loading.show({delay:0});

      let formData = new URLSearchParams(); 
      formData.append('id', data.item.id); 
      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.deleteContact, formData, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            //"Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){

              this.$notify({
                group: 'foo',
                title: 'Success',
                text: 'Delete Data Sector Business'
              });

              this.ApiCallDataCustomer();

            }else{  
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }
            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
          
        } );

        this.$loading.hide();

      },

    async ApiCallDataProvinsi(){

      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.getCountryState, null, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            "Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){
              this.dataProvinsi = res.data.resultdata;
            }else{
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }

            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
        } );

    },

    async ApiCallDataHolding(){

      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.getHolding, null, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            "Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){
              this.dataSelectHolding = res.data.resultdata;
            }else{
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }

            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
        } );

    },

    async ApiInputDataCustomer(){

      this.loading = true;

      this.errors = [];

      if (!this.input_form.name) {
        this.errors.push("Name required.");
      }
      if (!this.input_form.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.input_form.email)) {
        this.errors.push('Valid email required.');
      }

      if (this.errors.length == 0) {
       
        let formData = new FormData(); 
  
      
        if (Config.modeDev) console.log(JSON.stringify(this.input_form));
        let data = JSON.parse(JSON.stringify(this.input_form));
        
        if( data["id"] == "" ){
          delete data["id"];
        }
        
        if (Config.modeDev) console.log(this.input_form);
        if (Config.modeDev) console.log(data)

        if (Config.modeDev) console.log( JSON.stringify( data ) );
        formData.append('json', JSON.stringify( data ) ); 
        await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.insertContactMultipartFormType, formData, {
        headers:{
            "Forca-Token": this.userStart.token,
            "Accept": "application/json",
            "Content-Type": "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
        }
        }).then(res => {            
            if (Config.modeDev) console.log(res);
            if( res.data.codestatus == "S" ){
              if (Config.modeDev) console.log("Berhasil");
              this.$notify({
                group: 'foo',
                title: 'Success',
                text: 'Save Data Customer'
              });

              this.cancelEdit();
              this.ApiCallDataCustomer();
            }else{  
              this.wrongTokenCo(res)
            }

        }).catch( ( error ) => {
            if(this.is_runTest == false){ console.log(error) }

            try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
        } );

        this.input_form.id = "";
        this.loading = false;

      }
     

    },

    async ApiCallDataCustomer(){

      this.loading = true;
      let formData = new URLSearchParams(); 
      formData.append('perpage', 9999); 
      formData.append('page', 1); 

      if (Config.modeDev) console.log(this.userStart);
      await axios.post( process.env.VUE_APP_AUTHDOMAIN + this.data_url.getContact, formData, {
      headers:{
          "Forca-Token": this.userStart.token
          // "Accept": "application/json",
          // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
      }
      }).then(res => {            
          if (Config.modeDev) console.log(res);
          if( res.data.codestatus == "S" ){
              this.ordersData = res.data.resultdata;
              this.totalRows = this.ordersData.length;
          }else{
           
            this.wrongTokenCo(res)
          }

      }).catch( ( error ) => {
          if(this.is_runTest == false){ console.log(error) }

          try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error) } }
      } );

      this.loading = false;

      },

          /**
          * Search the table data with search input
          */
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        }
      }
    };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab :active="tab == 1" @click="tab = 1" title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Data Client</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <!-- <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
                            'badge-soft-success': `${row.value}` === 'Paid',
                            'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                      >{{ row.value}}</div>
                    </template> -->
                    <!-- <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <a
                        @click="editRecord(data)"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        @click="deleteRecord(data)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab :active="tab == 2" @click="tab = 2" title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{ input_form.id == "" ? "Add Client" : "Edit Client" }}</a>
                </template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="">
                      <div class="">
                        <!-- <h4 class="card-title">Add Customer</h4>
                        <p class="card-title-desc">
                          Please, Input data
                        </p> -->
                        <div class="row">
                          <div class="col-12">
                              <p v-if="errors.length">
                                <span class="text-danger" ><b>Please correct the following error(s):</b></span>
                                <ul>
                                  <li class="text-primary" v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
                                </ul>
                              </p>

                              <input type="hidden" name="id" v-model="input_form.id" />
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Holding"
                              >
                              <multiselect :options="dataSelectHolding" v-model="v_data_holding_computed" label="name"></multiselect>
                              </b-form-group>

                              <b-form-group
                                id="example text"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Name"
                                label-for="text"
                              >
                                <b-form-input for="text" value="" v-model="input_form.name"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Street"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.street"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Street 2"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.street2"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="City"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.city"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="State"
                                label-for="email"
                              >
                              <multiselect :options="dataProvinsi" v-model="v_data_provinsi_computed" label="name"></multiselect>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="ZIP"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.zip"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Job Position"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.job_position"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Phone"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.phone"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Mobile"
                                label-for="email"
                              >
                                <b-form-input value="" v-model="input_form.mobile"></b-form-input>
                              </b-form-group>
                              <b-form-group
                                id="example-email"
                                label-cols-sm="2"
                                label-cols-lg="2"
                                label="Email"
                                label-for="email"
                              >
                                <b-form-input id="email" type="email" value="" v-model="input_form.email"></b-form-input>
                              </b-form-group>

                              <b-button class="float-right" @click="ApiInputDataCustomer()" variant="primary">Save</b-button>

                              <b-button class="float-right mr-3" @click="cancelEdit()" v-show="this.input_form.id != ''" variant="warning">Cancel</b-button>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>